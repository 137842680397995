import React from 'react'
import { Box } from '@mui/material'
import Text from '../../assets/components/Text'
import RouteButton from '../../assets/components/RouteButton'
import { ShortcutOutlined } from '@mui/icons-material'
import { ROUTES } from '../../utils/api'
import TextParagraph from '../../assets/components/TextParagraph'

const Evaluation = () => {
  return (
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "20px" }}>
          <TextParagraph heading="Välkommen till utvärderingsverktyget!" body="Detta är ett verktyg utvecklat för att hjälpa användaren att uttrycka vad hen tyckte om att spela med Funki instrumenten. För att komma till utvärderingen trycker du på knappen nedan."></TextParagraph>
          <RouteButton title="Utvärdera" route={ROUTES.EVALUATION_TOOL} icon={ShortcutOutlined}></RouteButton>
      </Box>
  )
}

export default Evaluation
